import { cloudFunction } from "../helpers/cloudfunction";

interface ReorderMediaData {
  items: string[];
  attributeId: string;
  marketId: string;
  saleId: string;
  lotId: string;
}

interface ReorderResult {
  result: { status: "ok" } | { status: "error"; message: string };
}

export async function callReorderMedia(data: ReorderMediaData) {
  const reOrderMediaItems = cloudFunction<ReorderMediaData, ReorderResult>(
    "reOrderMediaItems"
  );

  return reOrderMediaItems(data);
}

import "../styles/globals.css";

import type { DecodedIdToken } from "firebase-admin/auth";
import { Inter } from "next/font/google";

import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import { TabName } from "../components/Layout";
import { initialiseFirebase } from "../initFirebase";

import LoadingBar from "@/components/LoadingBar";
import { useRouter } from "next/router";
import { SupportedCountryCode } from "types";
import { CommandPallet } from "../components/CommandPallet";
import { ConfirmationDialogProvider } from "../components/ConfirmationDialogProvider";
import { CurrentUserProvider } from "../components/CurrentUserProvider";
import PersonFlyout from "../components/PersonFlyout/PersonFlyout";
import { SearchProvider } from "../components/SearchProvider";

// remove console.log in prod
import { PosMoitorProvider } from "@/components/pos-monitor/PosMonitorProvider";
import { AccessLevel } from "@/data/AccessLevel";
import { SessionContext } from "@/data/studio-react/useStudioStream";
import { Toaster } from "sonner";
import "../data/logger";
import "react-photo-view/dist/react-photo-view.css";

const inter = Inter({ subsets: ["latin"] });

export interface MarketInfo {
  id: string;
  name: string;
  countryCode: SupportedCountryCode;
  logo?: string;
  primaryColour?: string;
  secondaryColour?: string;
}

export interface PropsWithSession {
  session: DecodedIdToken;
  markets: MarketInfo[];
  market: MarketInfo;
  activeTab: TabName;
  accessLevel: AccessLevel;
  [key: string]: any;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, props: PropsWithSession) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    initialiseFirebase();
  }, []);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  let sessionProps = useMemo(() => {
    return {
      session: pageProps.session,
      markets: pageProps.markets,
      market: pageProps.market,
      activeTab: pageProps.activeTab,
      accessLevel: pageProps.accessLevel,
    };
  }, [pageProps]);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <CurrentUserProvider>
        <SessionContext.Provider value={sessionProps}>
          <SearchProvider>
            <ConfirmationDialogProvider>
              <PosMoitorProvider>
                <LoadingBar />
                {getLayout(<Component {...pageProps} />, pageProps)}
                <PageAwarePersonFlyout />
                <CommandPallet></CommandPallet>
                <Toaster theme="dark" />
              </PosMoitorProvider>
            </ConfirmationDialogProvider>
          </SearchProvider>
        </SessionContext.Provider>
      </CurrentUserProvider>
    </>
  );
}

function PageAwarePersonFlyout() {
  let router = useRouter();

  // if we're on the sale page then don't mount the person flyout here. It'll be mounted lower in the tree where it has access to the sale data
  if (router.pathname === "/[marketId]/sales/[saleId]") {
    return null;
  }

  return <PersonFlyout />;
}

// let log = console.log;
// console.log = function (...args: any[]) {
//   // Prefix the log with the current time and give it a colour
//   let now = new Date();

//   let time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
//   let prefix = `%c${time} %c`;
//   let styles = ["color: #888", "color: #000"];

//   log(prefix, ...styles, ...args);
// };

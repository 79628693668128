import {
  LotAttributeController,
  useSaleAttributeControllers,
} from "@/data/attribute-definitions/attributeControls";
import { ALLOWED_COLS_DROP_ID } from "@/data/media/multipart-upload";
import React, { useCallback, useMemo } from "react";

import classNames from "classnames";
import { toast } from "sonner";
import { Accept, useDropzone } from "react-dropzone";

import { Lot, Media } from "types";
import { PhotoProvider } from "react-photo-view";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import {
  MEDIA_MESSAGES,
  mediaVariantSelector,
} from "@/data/media/media.helpers";
import { MediaListItem } from "../media/Media.ListItem";
import { useMediaUploader } from "@/data/media/MediaUploader.Provider";
import { MediaTypes } from "@/data/media/mediaQueue";

function MediaWranglerTab({ lot }: { lot: Lot | null }) {
  let attributes = useSaleAttributeControllers();

  const mediaAtt = useMemo(() => {
    return (
      ALLOWED_COLS_DROP_ID.map((id) => {
        return attributes.find((att) => att.definition.id === id);
      }).filter(Boolean) || []
    );
  }, [attributes]);

  if (!lot) {
    return <></>;
  }
  return (
    <>
      <div className="p-3 mt-3 w-full">
        <div className="w-full gap-4 ">
          <div className="flex shrink flex-wrap gap-4">
            <div className="mt-2 flex w-full flex-col">
              {mediaAtt.map((medAtt, i) => {
                if (!medAtt || (medAtt as any)?.kind) {
                  return;
                }
                let value = medAtt?.getValue(lot, null);
                const allowMultiple = medAtt.definition.id !== "mainImage";
                return (
                  <div key={medAtt.definition.id}>
                    <PhotoProvider maskOpacity={0.7}>
                      <div
                        className="relative  w-full rounded-md bg-white p-4 mt-2"
                        key={medAtt?.definition.id + i}
                      >
                        <h3 className="flex text-lg font-medium text-martEye-400 items-center pb-2">
                          {medAtt?.definition.name}
                          <span className="ml-2 text-xs bg-martEye-400 text-white px-2 rounded-full">
                            {medAtt?.definition.description}
                          </span>
                        </h3>

                        <div className="min-h-32 flex flex-col gap-2 ">
                          {allowMultiple ? (
                            <>
                              <MultipleAssets
                                lot={lot}
                                values={(value as Media[]) || []}
                                attribute={medAtt}
                                allowMultiple={true}
                                accept={{
                                  "image/png": [],
                                  "image/jpg": [],
                                  "image/jpeg": [],
                                  "video/mp4": [],
                                  "video/quicktime": [],
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <MultipleAssets
                                lot={lot}
                                values={value ? ([value] as Media[]) : []}
                                attribute={medAtt}
                                allowMultiple={false}
                                accept={{
                                  "image/png": [],
                                  "image/jpg": [],
                                  "image/jpeg": [],
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </PhotoProvider>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaWranglerTab;

const MultipleAssets = ({
  values,
  attribute,
  allowMultiple,
  lot,
  accept,
}: {
  lot: Lot;
  allowMultiple: boolean;
  values: Media[];
  attribute: LotAttributeController;
  accept: Accept | undefined;
}) => {
  const { onDropZoneDrop, reOrderMediaItems, deleteAsset, tempUploadedItems } =
    useMediaUploader();

  const optimisticMediaItems = useMemo(() => {
    const itemInOptimistic = tempUploadedItems.get(lot?.id);
    if (!itemInOptimistic) {
      return [];
    }

    const attributeInOptimistic =
      itemInOptimistic[attribute.definition.id as MediaTypes];

    if (!attributeInOptimistic) {
      return [];
    }
    return attributeInOptimistic;
  }, [tempUploadedItems, lot]);

  const fileUploadID = `file-upload-${attribute.definition.id}`;

  let { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    autoFocus: false,
    noClick: true,
    multiple: allowMultiple,
    maxSize: allowMultiple ? 500 * 1024 * 1024 : 50 * 1024 * 1024, // 500MB
    onDropAccepted: (f) =>
      onDropZoneDrop({
        files: f,
        type: attribute.definition.id as MediaTypes,
        lotId: lot?.id,
        marketId: lot?.marketId,
        saleId: lot?.saleId,
      }),
    onDropRejected: (fileRejections) => {
      console.error(fileRejections);
      for (let rejection of fileRejections) {
        if (rejection.errors[0].code === "file-invalid-type") {
          toast.error(MEDIA_MESSAGES.NON_SUPPORTED_TYPE);
        } else if (rejection.errors[0].code === "file-too-large") {
          toast.error(MEDIA_MESSAGES.FILE_TOO_LARGE);
        } else {
          toast.error(rejection.errors[0].message);
        }
      }
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  // TODO :: This is jumpy as it waits for FIREBASE TO come back with data
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over) return;

      const activeItem = values.find((item) => item.url === active.id);
      const overItem = values.find((item) => item.url === over.id);

      if (!activeItem || !overItem) {
        return;
      }
      // Check if items has been competed uploaded
      const selectImageVariantOver = mediaVariantSelector(
        overItem,
        "thumbnail"
      );
      const selectImageVariantActive = mediaVariantSelector(
        activeItem,
        "thumbnail"
      );

      if (selectImageVariantOver?.isLocal) {
        toast.error(MEDIA_MESSAGES.CANT_SORT_LOCAL_IMAGE);
        return;
      }
      if (selectImageVariantActive?.isLocal) {
        toast.error(MEDIA_MESSAGES.CANT_SORT_LOCAL_IMAGE);
        return;
      }
      const activeItemIndex = values.findIndex(
        (item) => item.url === active.id
      );
      const overItemIndex = values.findIndex((item) => item.url === over.id);

      const order = arrayMove(values, activeItemIndex, overItemIndex);

      reOrderMediaItems({
        newOrder: order,
        type: attribute.definition.id as MediaTypes,
        lotId: lot?.id,
        marketId: lot?.marketId,
        saleId: lot?.saleId,
      });
    },
    [values, reOrderMediaItems, lot, attribute]
  );

  const hasAnyItems = (values.length || 0) + optimisticMediaItems.length;

  return (
    <>
      <div className="flex gap-2 absolute right-4 top-2">
        <div className="flex justify-end items-center">
          <span className="text-xs text-martEye-400  px-2 rounded-full border border-martEye-400">
            {hasAnyItems} - Items
          </span>
        </div>
        <label
          htmlFor={fileUploadID}
          className="cursor-pointer rounded-md bg-white font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500 underline text-sm"
        >
          <span>{"Upload a file"}</span>
          <input
            id={fileUploadID}
            name={fileUploadID}
            type="file"
            className="sr-only"
            {...getInputProps()}
          />
        </label>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[]}
      >
        <div
          {...getRootProps()}
          className={classNames(`
        ${
          isDragActive
            ? "opacity-95 border-2 border-martEye-400 rounded-lg"
            : "border-2 border-white"
        }
        bg-gray-200/50  gap-2 p-2 h-[30vh] overflow-y-auto rounded-lg
       
        `)}
        >
          {hasAnyItems ? (
            <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
              {!!values.length && (
                <SortableContext
                  items={values.map((item) => ({ id: item.url }))}
                  strategy={rectSortingStrategy}
                >
                  {values.map((value, i) => (
                    <MediaListItem
                      disableReorder={!allowMultiple}
                      value={value}
                      key={value.url}
                      deleteAsset={deleteAsset}
                    />
                  ))}
                </SortableContext>
              )}
              {!!optimisticMediaItems.length && (
                <>
                  {optimisticMediaItems.map((value, i) => (
                    <MediaListItem
                      disableReorder={!allowMultiple}
                      value={value}
                      key={value.url + i}
                      deleteAsset={deleteAsset}
                    />
                  ))}
                </>
              )}
            </div>
          ) : (
            <>
              <div className="w-full flex justify-center items-center h-full flex-1 min-h-32">
                <span className=" text-sm pr-1">
                  No Asset Uploaded Drag Asset Here or{" "}
                </span>
                <label
                  htmlFor={fileUploadID}
                  className="cursor-pointer rounded-md font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500 underline text-sm"
                >
                  <span>{isDragActive ? " Drop the File" : " Upload"}</span>
                  <input
                    id={fileUploadID}
                    name={fileUploadID}
                    type="file"
                    className="sr-only"
                    {...getInputProps()}
                  />
                </label>
              </div>
            </>
          )}
        </div>
      </DndContext>
    </>
  );
};

import { toast } from "sonner";
import { multiPartUploadFile, ProgressCallback } from "./multipart-upload";
import { BehaviorSubject } from "rxjs";

export type UploadFiles = {
  files: File[];
  type: MediaTypes;
  lotId: string;
  marketId: string;
  saleId: string;
};
export type UploadFile = {
  file: File;
  type: MediaTypes;
  lotId: string;
  marketId: string;
  saleId: string;
};

export type MediaTypes = "media" | "mainImage";

type UploadProgress = {
  uploadFile: UploadFile;
  state: ProgressCallback["state"];
  progress: number;
  total: number;
};

// Media Uploader Queue
export class MediaUploaderQueue {
  private _queue: UploadFile[] = [];
  private _currentUploading: UploadFile | null = null;
  currentProgress$: BehaviorSubject<UploadProgress | null> =
    new BehaviorSubject<UploadProgress | null>(null);

  constructor() {
    this._queue = [];
    this._currentUploading = null;
    this.currentProgress$ = new BehaviorSubject<UploadProgress | null>(null);
  }

  add(item: UploadFile) {
    this._queue.push(item);
    if (this._currentUploading) {
      toast.info(`Upload Queued `, {});
    }
    this.process();
  }

  process() {
    if (this._queue.length === 0 || this._currentUploading) {
      return;
    }

    let item = this._queue.shift();

    if (!item) {
      return;
    }

    this._currentUploading = item;

    this.uploadFile(item).finally(() => {
      this._currentUploading = null;
      this.process();
    });
  }

  async uploadFile(data: UploadFile): Promise<File | undefined> {
    if (!data) {
      return;
    }

    const filePath = `${data.marketId}/${data.saleId}/${data.lotId}/${data.type}/${data.file.name}`;

    try {
      toast.info(`Upload Started `, {});
      await multiPartUploadFile({
        filePath,
        file: data.file,
        progressCallback: (progress) => {
          this.currentProgress$.next({
            uploadFile: data,
            state: progress.state,
            progress: progress.progress,
            total: progress.total,
          });
        },
      });
      toast.info(`File Uploaded `);
      return data.file;
    } catch (error) {
      toast.error(`Upload Failed`);
    }
  }

  get length() {
    return this._queue.length;
  }
}

import { CurrenciesWithGuinea, Lot } from "../types";

// Watch out as this can throw

export function getTotalPriceInCents(lot: Lot) {
  throw new Error(
    "Don't implement this on the client - the server will add it to the lot"
  );
}

export function formatAsCurrency(
  currency: CurrenciesWithGuinea | null,
  valueInCents: number | null | undefined,
  removeTrailZeroes = true,
  decimalPlaces = 2
) {
  if (valueInCents === undefined || valueInCents === null) {
    return null;
  }

  if (currency === "GUINEA-GBP") {
    let amount = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
      maximumFractionDigits: decimalPlaces,
    }).format(valueInCents / 100);

    if (removeTrailZeroes) {
      amount = amount.replace(
        new RegExp(`\\.${Array(decimalPlaces + 1).join("0")}$`),
        ""
      );
    }

    return `${amount.replace("£", "")}g`;
  }

  let amount;

  if (valueInCents === -0) {
    valueInCents = 0;
  }

  if (currency === null) {
    amount = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: decimalPlaces,
    }).format(valueInCents / 100);
    amount = amount.replace("£", "");
  } else {
    amount = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: currency,
      maximumFractionDigits: decimalPlaces,
    }).format(valueInCents / 100);
  }

  if (removeTrailZeroes) {
    amount = amount.replace(
      new RegExp(`\\.${Array(decimalPlaces + 1).join("0")}$`),
      ""
    );
  }

  return amount;
}

export function currencyToSymbol(
  currency?: CurrenciesWithGuinea | null | undefined
) {
  switch (currency) {
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GUINEA-GBP":
      return "g";
    default:
      return currency;
  }
}

export function currencyToSubunitSymbol(
  currency?: CurrenciesWithGuinea | null | undefined
) {
  switch (currency) {
    case "GBP":
      return "p";
    case "EUR":
      return "c";
    case "USD":
      return "¢";
    case "GUINEA-GBP":
      return "p"; // Assuming guineas use pence as subunits
    default:
      return "";
  }
}

export function formatAsCurrencySubunit(
  currency: CurrenciesWithGuinea | null,
  valueInCents: number | null | undefined
) {
  if (valueInCents === null || valueInCents === undefined) {
    return "-";
  }

  return `${valueInCents}${currencyToSubunitSymbol(currency)}`;
}

export function convertCentsToEur(cents: number | string | null | undefined) {
  if (cents === 0 || cents === null || cents === undefined) {
    return undefined;
  }

  let centsAsNumber = Number(cents);
  let val = (centsAsNumber / 100).toFixed(2);

  if (val.endsWith(".00")) {
    return val.replace(".00", "");
  }

  return val;
}

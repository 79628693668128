import React, { useCallback, useEffect, useState } from "react";
import { useStudioStream } from "../studio-react/useStudioStream";
import {
  ALLOWED_COLS_DROP_ID,
  isMediaAllowedForId,
  multiPartUploadFile,
  unlinkAssets,
} from "./multipart-upload";
import { toast } from "sonner";
import { createFakeMedia, MEDIA_MESSAGES, renameFile } from "./media.helpers";
import { Lot, Media } from "types";
import { callReorderMedia } from "../studio-react/actions/reorderMedia";
import { GridColumn, Item } from "@glideapps/glide-data-grid";
import {
  MediaTypes,
  MediaUploaderQueue,
  UploadFile,
  UploadFiles,
} from "./mediaQueue";

interface MediaUploader {
  reOrderMediaItems: (d: {
    newOrder: Media[];
    type: MediaTypes;
    lotId: string;
    marketId: string;
    saleId: string;
  }) => Promise<void>;
  onDropZoneDrop: (d: UploadFiles) => Promise<void>;
  deleteAsset: (filePath: string) => Promise<void>;
  onGridDrop: (
    cell: Item,
    dataTransfer: DataTransfer | null,
    columns: GridColumn[],
    lots: Lot[]
  ) => Promise<void>;
  tempUploadedItems: OptimisticMediaUpload;
}

type OptimisticMediaUpload = Map<
  string,
  {
    mainImage?: Media[];
    media?: Media[];
  }
>;

const MediaUploaderContext = React.createContext<MediaUploader>({
  onDropZoneDrop: () => Promise.resolve(),
  deleteAsset: () => Promise.resolve(),
  reOrderMediaItems: () => Promise.resolve(),
  onGridDrop: () => Promise.resolve(),
  tempUploadedItems: new Map(),
});

export function useMediaUploader() {
  return React.useContext(MediaUploaderContext);
}

const mediaUploaderQueue = new MediaUploaderQueue();

/**
 * The idea here would be that the the Uploader Would Take a temp file and display it here - Keep track of that Lot item if that lot items changes comes in react no that
 */
export function MediaUploaderProvider(props: { children: React.ReactNode }) {
  let lotInfo = useStudioStream("sale:lots-info");

  // // Add Item to Upload Queue
  const [tempUploadedItems, setTempUploadedItems] =
    useState<OptimisticMediaUpload>(new Map());

  const setLocalMediaItem = (
    mediaItem: Media,
    lotId: string,
    type: MediaTypes
  ) => {
    setTempUploadedItems((ps) => {
      const newMap = new Map(ps);
      if (newMap.has(lotId)) {
        const existing = newMap.get(lotId);
        if (existing?.mainImage && type === "mainImage") {
          existing.mainImage = [mediaItem];
          newMap.set(lotId, existing);
          return newMap;
        }
        if (existing?.media && type === "media") {
          const alreadyPasedMedia = existing.media.find(
            (m) => m.fileName === mediaItem.fileName
          );
          if (!alreadyPasedMedia) {
            existing.media.push(mediaItem);
          }
          if (alreadyPasedMedia?.variants.thumbnail?.isLocal) {
            const indexOfThumbnail = existing.media.findIndex(
              (m) => m.fileName === mediaItem.fileName
            );
            existing.media[indexOfThumbnail] = mediaItem;
          }
          newMap.set(lotId, existing);
          return newMap;
        }
      } else {
        if (type === "mainImage") {
          newMap.set(lotId, { mainImage: [mediaItem] });
        }
        if (type === "media") {
          newMap.set(lotId, { media: [mediaItem] });
        }
      }
      return newMap;
    });
  };

  // Subscribes to the CurrentUpload Progress of an Item in the Queue
  useEffect(() => {
    const uploadProgressSubscription =
      mediaUploaderQueue.currentProgress$.subscribe((progress) => {
        if (progress) {
          const tempMedia = createFakeMedia(progress.uploadFile.file, {
            state: progress.state,
            progress: progress.progress,
            total: progress.total,
          });

          setLocalMediaItem(
            tempMedia,
            progress.uploadFile.lotId,
            progress.uploadFile.type
          );
        }
      });

    return () => {
      uploadProgressSubscription.unsubscribe(); // Properly unsubscribe to clean resources
    };
  }, []);

  const addItemToLocal = useCallback(
    (file: File, lotId: string, type: MediaTypes) => {
      const tempMedia = createFakeMedia(file);
      setLocalMediaItem(tempMedia, lotId, type);
    },
    [setTempUploadedItems, createFakeMedia]
  );

  // Removes Local Items for Local Uploads
  useEffect(() => {
    for (let [key, lotInOptimistic] of tempUploadedItems) {
      const lot = lotInfo.data.find((lot) => lot.id === key);
      if (!lot) {
        continue;
      }
      const optimisticMedia = lotInOptimistic["media"];
      const lotHasMedia = lot.attributes["media"] as Media[];

      const lotHasMainImage = lot.attributes["mainImage"] as Media;
      const optimisticMainImage = lotInOptimistic["mainImage"];

      if (optimisticMedia && lotHasMedia?.length) {
        const mediaToRemove = optimisticMedia.find((m) => {
          return lotHasMedia.find((om) => om.fileName === m.fileName);
        });
        if (mediaToRemove) {
          setTempUploadedItems((ps) => {
            const newMap = new Map(ps);
            const existing = newMap.get(key);
            if (existing?.media) {
              // Filter out the media item that needs to be removed
              const updatedMedia = existing.media.filter(
                (m) => m.fileName !== mediaToRemove.fileName
              );
              if (updatedMedia.length === 0) {
                // If no media items left, remove the media property
                const { media, ...rest } = existing;
                if (Object.keys(rest).length === 0) {
                  newMap.delete(key); // Remove the entire entry if empty
                } else {
                  newMap.set(key, rest);
                }
              } else {
                // Update with filtered media array
                newMap.set(key, { ...existing, media: updatedMedia });
              }
            }
            return newMap;
          });
        }
      }

      if (lotHasMainImage && optimisticMainImage) {
        const mediaToRemove = optimisticMainImage.find((m) => {
          return m.fileName === lotHasMainImage.fileName;
        });
        if (mediaToRemove) {
          setTempUploadedItems((ps) => {
            const newMap = new Map(ps);
            if (ps.has(lot.id)) {
              const existing = ps.get(lot.id);
              if (existing?.mainImage) {
                newMap.delete(lot.id);
              }
              return newMap;
            }
            return newMap;
          });
        }
      }
    }
  }, [lotInfo.data, tempUploadedItems, setTempUploadedItems]);

  const onDropZoneDrop = useCallback(
    async ({ files, type, lotId, saleId, marketId }: UploadFiles) => {
      // let upFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const renamedFile = renameFile(file);

        // Optimistic UI
        addItemToLocal(renamedFile, lotId, type);

        const uFile: UploadFile = {
          file: renamedFile,
          marketId,
          type,
          lotId,
          saleId,
        };

        mediaUploaderQueue.add(uFile);
      }
    },
    [addItemToLocal, renameFile]
  );

  const onGridDrop = useCallback(
    async (
      cell: Item,
      dataTransfer: DataTransfer | null,
      columns: GridColumn[],
      lots: Lot[]
    ) => {
      const droppedCol = columns[cell[0]];
      if (!droppedCol?.id) {
        return;
      }
      if (droppedCol?.id && !ALLOWED_COLS_DROP_ID.includes(droppedCol?.id)) {
        toast.error(`Please drop the file in the appropriate Column`);
        return;
      }

      if (dataTransfer === null) {
        // TODO :: How Would this happen?
        toast.error(`Empty Data Object`);
        return;
      }

      const { files } = dataTransfer;
      // This only supports one image, for simplicity.
      if (files.length < 1) {
        toast.error(`No File found`);
        return;
      }
      const { supportedTypes, maxFiles, id } = isMediaAllowedForId(
        droppedCol.id as any
      );
      if (files.length > maxFiles) {
        toast.error(`Too Many Files`);
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (!supportedTypes.includes(file.type)) {
          toast.error(`File Type Not Supported`);
          return;
        }

        let lot = lots[cell[1]];
        const renamedFile = renameFile(file);

        const uFile: UploadFile = {
          file: renamedFile,
          marketId: lot.marketId,
          type: droppedCol.id as MediaTypes,
          lotId: lot.id,
          saleId: lot.saleId,
        };
        addItemToLocal(renamedFile, lot.id, droppedCol.id as MediaTypes);

        mediaUploaderQueue.add(uFile);
      }
    },
    [lotInfo.data, addItemToLocal]
  );

  /**
   * Deletes the asset remote storage
   */
  const deleteAsset = useCallback(
    async (filePath: string): Promise<void> => {
      try {
        // TODO:: DO LOCAL UNLINKING HERE
        toast.info(`Removing Media `, {});
        await unlinkAssets(filePath);
        toast.info(`Media Removed`);
      } catch (error) {
        throw error;
      }
    },
    [unlinkAssets, lotInfo.data]
  );

  const reOrderMediaItems = useCallback(
    async ({
      newOrder,
      lotId,
      marketId,
      saleId,
      type,
    }: {
      newOrder: Media[];
      type: MediaTypes;
      lotId: string;
      marketId: string;
      saleId: string;
    }) => {
      // const oldOrder = [...mediaItems];
      // setMediaItems([...newOrder]);
      const itemsNewOrder = newOrder.map((i) => i.url);
      try {
        await callReorderMedia({
          items: itemsNewOrder,
          marketId: marketId,
          attributeId: type,
          saleId: saleId,
          lotId: lotId,
        });
      } catch (error) {
        toast.error(MEDIA_MESSAGES.REORDER_FAILED);
        // setMediaItems([...oldOrder]);
      }
    },
    [lotInfo.data, callReorderMedia]
  );

  return (
    <MediaUploaderContext.Provider
      value={{
        reOrderMediaItems,
        onDropZoneDrop,
        onGridDrop,
        deleteAsset,
        tempUploadedItems,
        // uploadFile,
      }}
    >
      {props.children}
    </MediaUploaderContext.Provider>
  );
}

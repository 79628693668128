import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PhotoIcon,
  TagIcon,
} from "@heroicons/react/24/solid";

import { flyoutErrors } from "@/components/LotFlyout/LotValidation";
import { getLotStatus } from "@/data/lots";
import { useStudioStream } from "@/data/studio-react/useStudioStream";
import { memo, useCallback, useMemo } from "react";
import { Lot, Sale } from "../../types";
import Flyout from "../Flyout/Flyout";
import { useFlyout } from "../Flyout/useFlyout";
import { StandardTabs } from "../StandardTabs";
import { ItemsTab } from "./ItemsTab";
import { OverviewTab } from "./OverviewTab";

import { ConfirmationDialogueLotFlyoutMount } from "../ConfirmationDialogProvider";
import { LotCompletion } from "./LotValidation";
import MediaWranglerTab from "./MediaWranglerTab";

export function useOpenLotFlyout() {
  let [isFlyoutOpen, openFlyout, closeFlyout] = useFlyout(["l", "lt"]);

  let openLotFlyout = useCallback(
    (
      lotId: string | null,
      tab?: number | undefined | null,
      clearOthers: boolean = true
    ) => {
      let params = lotId
        ? {
            l: lotId,
            lt: tab,
          }
        : null;
      openFlyout(params, clearOthers);
    },
    [openFlyout]
  );

  return {
    openLotFlyout,
    lotFlyoutIsOpen: isFlyoutOpen,
    closeLotFlyout: closeFlyout,
  };
}

export default function LotFlyout() {
  let { openLotFlyout } = useOpenLotFlyout();

  return (
    <Flyout
      urlParams={["l", "lt"]}
      submountDialogs={[ConfirmationDialogueLotFlyoutMount]}
    >
      {(urlParamValues, closeFlyout, flyoutIsOpen) => {
        let lotId = urlParamValues ? urlParamValues["l"] : null;

        let lotInfo = useStudioStream("sale:lots-info");
        let [previousLotId, lot, nextLotId] = useMemo(() => {
          let index = lotInfo.data.findIndex((l) => l.id === lotId);
          let lot = index !== -1 ? lotInfo.data[index] : null;
          let previousLotId = null;
          let nextLotId = null;
          if (lot) {
            if (index > 0) {
              previousLotId = lotInfo.data[index - 1].id;
            }
            if (index < lotInfo.data.length - 1) {
              nextLotId = lotInfo.data[index + 1].id;
            }
          }
          return [previousLotId, lot, nextLotId];
        }, [lotInfo.data, lotId]);

        let sale = useStudioStream("sale:sale");

        return (
          <Flyout.Body>
            <Flyout.Title>
              <div className="flex flex-row pb-2">
                <div>
                  <span className="inline-flex gap-1.5">
                    <button
                      type="button"
                      className="rounded-full bg-martEye-600 p-1.5 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 disabled:cursor-not-allowed disabled:opacity-50 hover:bg-martEye-400"
                      onClick={() => {
                        openLotFlyout(previousLotId ?? null);
                      }}
                      disabled={!previousLotId}
                    >
                      <span className="sr-only">Previous Lot</span>
                      <ChevronLeftIcon
                        className="h-5 w-5 -translate-x-px"
                        aria-hidden="true"
                      />
                    </button>

                    <button
                      type="button"
                      className="rounded-full bg-martEye-600 p-1.5 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-martEye-400 disabled:cursor-not-allowed disabled:opacity-50 hover:bg-martEye-400"
                      onClick={() => {
                        openLotFlyout(nextLotId ?? null);
                      }}
                      disabled={!nextLotId}
                    >
                      <span className="sr-only">Next Lot</span>
                      <ChevronRightIcon
                        className="h-5 w-5 translate-x-px"
                        aria-hidden="true"
                      />
                    </button>
                  </span>
                </div>

                <span className="mx-2">Lot {lot?.lotNumber}</span>
              </div>

              <div className="text-sm font-light text-gray-400">
                Description:{" "}
                <span className="font-bold capitalize">
                  {lot?.generated?.description}
                </span>
              </div>
              <div className="text-sm font-light text-gray-400">
                Status:{" "}
                <span className="font-bold capitalize">
                  {lot ? getLotStatus(lot).status : null}
                </span>
              </div>
            </Flyout.Title>
            <LotFlyoutInner
              lot={lot}
              sale={sale}
              activeTab={urlParamValues ? urlParamValues["lt"] : null}
            ></LotFlyoutInner>
          </Flyout.Body>
        );
      }}
    </Flyout>
  );
}

function LotFlyoutCompletionBar({ lot }: { lot: Lot | null | undefined }) {
  if (!lot) return null;

  return (
    <div className="sticky z-10 top-0 px-3 py-3 flex flex-row text-martEye-700 w-full min-h-[76px] bg-martEye-100">
      <div className="w-full">
        <LotCompletion lot={lot} />
      </div>
    </div>
  );
}

const LotFlyoutCompletionBarMemo = memo(LotFlyoutCompletionBar);

function LotFlyoutInner(props: {
  lot?: Lot | null;
  sale: Sale | null;
  activeTab: string | null;
}) {
  let lot = props.lot;
  let sale = props.sale;

  const tabs = useMemo(() => {
    let itemCount = Object.keys(lot?.itemMap || {}).length;
    let itemsTitle = `Items (${itemCount})`;

    // Need to add in validation issues here and pass down to the relevant tabs
    const { itemIssues = [], overviewIssues = [] } = flyoutErrors(lot);

    let tabs = [
      {
        title: "Overview",
        icon: <Cog8ToothIcon className="mr-2 -ml-1 h-4 w-4" />,
        content: (
          <OverviewTab
            lot={lot || null}
            sale={sale}
            issues={overviewIssues}
          ></OverviewTab>
        ),
        badgeCount: overviewIssues.length ?? null,
      },
      {
        title: itemsTitle,
        icon: <TagIcon className="mr-2 -ml-1 h-4 w-4" />,
        content: <ItemsTab lot={lot || null} issues={itemIssues}></ItemsTab>,
        badgeCount: itemIssues.length ?? null,
      },
      {
        title: "Media Items",
        icon: <PhotoIcon className="mr-2 -ml-1 h-4 w-4" />,
        content: <MediaWranglerTab lot={lot || null} />,
        badgeCount: itemIssues.length ?? null,
      },
    ];

    return tabs;
  }, [lot, sale]);

  let initialTab = 0;

  if (props.activeTab !== undefined && props.activeTab !== null) {
    initialTab = parseInt(props.activeTab);
  }

  return (
    <div className="relative flex-grow divide-y divide-gray-200 bg-martEye-700">
      <StandardTabs
        tabs={tabs}
        urlParam={"lt"}
        initialTab={initialTab}
        header={<LotFlyoutCompletionBarMemo lot={lot} />}
      ></StandardTabs>
    </div>
  );
}

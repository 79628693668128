import { Media } from "types";
import { autoId } from "../ids";
import { ProgressCallback } from "./multipart-upload";

export enum MEDIA_MESSAGES {
  CANT_SORT_LOCAL_IMAGE = "Please wait until upload is complete before sorting media",
  NON_SUPPORTED_TYPE = "File not Supported ",
  FILE_TOO_LARGE = "File too Large",
  REORDER_FAILED = "Reordering failed",
}

export const createFakeMedia = (
  file: File,
  uploadProgress?: ProgressCallback
): Media => {
  const imgUrl = URL.createObjectURL(file);
  const media: Media = {
    fileName: file.name,
    fileType: file.type,
    path: imgUrl,
    url: imgUrl,
    uploadProgress: uploadProgress || undefined,
    variants: {
      thumbnail: {
        url: imgUrl,
        isProcessing: true,
        isLocal: true,
        type: file.type as any,
      },
    },
  };
  return media;
};
/**
 * Renames file with ID - To make Uploads Unique
 */
export const renameFile = (file: File): File => {
  const splitName = file.name.split(".");
  const fileType = splitName.pop();

  const nameSuffix = autoId();
  const newName = `${splitName.join("-")}-${nameSuffix}.${fileType}`;

  const myNewFile = new File([file], newName, { type: file.type });

  return myNewFile;
};

/**
 * Selects media based on Variant - Will not select Compressed Unless specified as Prio
 */
export const mediaVariantSelector = (
  media: Media,
  prio: keyof Media["variants"] = "thumbnail"
) => {
  const selectImageVariant =
    media.variants[prio] ||
    media.variants["small"] ||
    media.variants["medium"] ||
    media.variants["large"] ||
    media.variants["thumbnail"];
  return selectImageVariant;
};
